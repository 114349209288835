<template>
  <div class="production_wrap">
    <div class = "box">
      <div class="about-title">经营范围</div>
      <div class = "block-line"></div>
      <div class="about-container">
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">公司作为我市自然资源领域国土空间规划行业的核心力量，主要业务涵盖国土空间规划、国土及自然资源、测绘地理信息、数字规划创新板块等方面，构建了全链条跨专业业务体系。</span> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">1.国土空间规划板块：包括规划咨询智库、总师服务，战略规划、远景规划编制，各级各类国土空间总体规划、详细规划及村庄规划、各类专项规划编制，选址论证等规划咨询业务。</span> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">2.土地及自然资源板块：包括各类型国土资源规划、评价、研究等，土地调查、自然资源专项调查，自然保护地、生态修复规划编制，林业、海洋、湿地、矿产等各类型自然资源专项规划业务。</span> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">3.全过程咨询板块：包括全链条技术咨询服务，策划、研究、可研等项目咨询，前期工作代建、工程咨询、造价咨询、市政公用工程咨询等，招标代理、政府采购代理、投标业务等。</span> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">4.数字规划创新板块：包括国土空间规划、城市建设、企业管理等数字化技术服务及系统开发，规划实施监测网络、“一张图”、城市体检评估、三维应用、建设投资地图等数字化服务。</span> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">5.测绘与地理信息板块：包括大地测量、工程测量，地理信息系统，摄影测量与遥感，不动产测量、工程测量、市政工程测量，地图编制，实景三维数据建设，联网地图服务等基础测绘业务。</span> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">6.市政交通板块：包括各类型各层级城市道路交通规划、市政基础设施规划、管网规划等编制及技术咨询业务，道路、桥梁、市政、风景园林等专业建设工程设计。</span> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">工程咨询中心经营范围覆盖：工程项目咨询服务，招标代理，政府采购代理咨询，工程造价咨询，工程管理服务，工程监理服务，工程勘察活动，土地规划服务，市场调查，经济信息咨询，健康咨询，环保咨询，信息技术咨询服务，节能技术推广服务、信用服务。</span> 
        </p>
        <p style="text-indent:3em;line-height:3;text-align:justify;">
          <span style="font-size:16px;">市城市交通设计研究院经营范围覆盖：市政道路、桥梁、排水、风景园林等专业的建设工程设计、技术咨询服务和测绘服务，工程设计市政行业、风景园林工程设计，工程咨询，工程测量、界线与不动产测绘等。</span> 
        </p>
      </div>
    </div>
    <CallMe />
    <MobileCallme />
  </div>
</template>

<script>
import CallMe from '@/components/callme/index'
import MobileCallme from '@/components/callme/MobileCallme.vue'
export default {
  data() {
    return {
    }
  },
  components: {
    CallMe,
    MobileCallme,
  },
}
</script>

<style lang="scss" scoped>
.production_wrap {
  padding-top: 200px;
  width: 100%;
}
</style>